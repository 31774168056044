import React from 'react'
import './Navbar.css'
import Toggle from './Toggle'
import { Link } from 'react-scroll'

const Navbar = () => {
  return (
    <>
      <section className="nav" id="Navbar">
        <div className="n-left">
          <h3 className="logo">Ahmad's Site</h3>
          <span><Toggle/></span>
        </div>
        <div className="n-right">
          <ul className='n-lists'>
            <Link spy={true} to='Navbar' smooth={true} >
            <li>Home</li>
            </Link>
            <Link spy={true} to='Services' smooth={true} >
            <li>Services</li>
            </Link>
            <Link spy={true} to='Work' smooth={true} >
            <li>Work</li>
            </Link>
            <Link spy={true} to='Portfolio' smooth={true} >
            <li>Porfolio</li>
            </Link>
            <Link spy={true} to='Testmonials' smooth={true} >
            <li>Testmonials</li>
            </Link>
          </ul>
          <Link spy={true} to='Contact' smooth={true} >
          <button className='btn n-btn'>Contact</button>
          </Link>
        </div>
      </section>
    </>
  )
}

export default Navbar
