import React from 'react'
import './Portfolio.css'

import img1 from '../../img/sidebar.png'
import img2 from '../../img/ecommerce.png'
import img3 from '../../img/hoc.png'
import img4 from '../../img/musicapp.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { themeContext } from "../../Context";
import { useContext } from "react";


const Portfolio = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;
  return (
    <>
     <section className="portfolio" id='Portfolio'>
      <div className="profolio-color">
      <span     style={{
                background: darkMode ? "black" : "",
                color: darkMode ? "white" : "",
              }}>Recent Projects</span>
      <span>Portfolio</span>
      </div>

      <Swiper
       spaceBetween={30}
       slidesPerView={3}
       grabCursor={true}
      >
        <SwiperSlide>
          <img src={img1} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img2} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img3} alt="" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={img4} alt="" />
        </SwiperSlide>
      </Swiper>

     </section>
    </>
  )
}

export default Portfolio
