import React from 'react'
import './Footer.css'
import wave from '../../img/wave.png'
import { MdFacebook } from 'react-icons/md';
import { BsGithub } from 'react-icons/bs';
import { BsInstagram } from 'react-icons/bs';

const Footer = () => {
  return (
    <div className='footer'>
      <img src={wave} alt="" />
      <div className="icons">
        <MdFacebook/>
        <BsGithub/>
        <BsInstagram/>
      </div>
      <span>Copyright © 2022 Ahmad Shoukat</span>
    </div>
  )
}

export default Footer
