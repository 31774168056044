import React from "react";
import "./Experience.css";

import { themeContext } from "../../Context";
import { useContext } from "react";
import CountUp from "react-countup";

const Experience = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;
  return (
    <>
      <section className="experience" id="Experience">
        <div className="achivement">
          <div
            className="circle"
            style={{
              background: darkMode ? "black" : "",
              color: darkMode ? "white" : "",
            }}
          >
            <CountUp start={1} end={8} duration={1} />
          </div>
          <span>Years</span>
          <span>Experience</span>
        </div>
        <div className="achivement">
          <div
            className="circle"
            style={{
              background: darkMode ? "black" : "",
              color: darkMode ? "white" : "",
            }}
          >
            20+
          </div>
          <span>Completed</span>
          <span>Projects</span>
        </div>
        <div className="achivement">
          <div
            className="circle"
            style={{
              background: darkMode ? "black" : "",
              color: darkMode ? "white" : "",
            }}
          >
            5+
          </div>
          <span>Companies</span>
          <span>Work</span>
        </div>
      </section>
    </>
  );
};

export default Experience;
