import React from 'react'
import './Testmonial.css'
import one from '../../img/profile1.jpg'
import two from '../../img/profile2.jpg'
import three from '../../img/profile3.jpg'
import four from '../../img/profile4.jpg'
import five from '../../img/profile5.jpg'
import { Pagination } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';

import { themeContext } from "../../Context";
import { useContext } from "react";


const Clients =[
  
  {
  img:one,
  review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error expedita ratione officia itaque sequi unde nemo! Sint aliquam amet porro esse praesentium iusto quaerat provident perferendis blanditiis eligendi odio tempora ad, ipsam cumque unde.'
},
  {
  img:two,
  review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error expedita ratione officia itaque sequi unde nemo! Sint aliquam amet porro esse praesentium iusto quaerat provident perferendis blanditiis eligendi odio tempora ad, ipsam cumque unde.'
},
  {
  img:three,
  review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error expedita ratione officia itaque sequi unde nemo! Sint aliquam amet porro esse praesentium iusto quaerat provident perferendis blanditiis eligendi odio tempora ad, ipsam cumque unde.'
},
  {
  img:four,
  review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error expedita ratione officia itaque sequi unde nemo! Sint aliquam amet porro esse praesentium iusto quaerat provident perferendis blanditiis eligendi odio tempora ad, ipsam cumque unde.'
},
  {
  img:five,
  review: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Error expedita ratione officia itaque sequi unde nemo! Sint aliquam amet porro esse praesentium iusto quaerat provident perferendis blanditiis eligendi odio tempora ad, ipsam cumque unde.'
},
]



const Testmonial = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;
  return (
    <>
      <section className="testmonial" id='Testmonials'>
        <div className="testmonial-color">
          <span>Clients always get &nbsp;</span>
          <span>Exceptional Work &nbsp;</span>
          <span>from me...</span>
          <div className='blur t-blur1' style={{backgroung: 'var(--purple)'}}></div>
        <div className='blur t-blur2' style={{backgroung: 'skyblue'}}></div>
        </div>
        <Swiper 
        modules={[Pagination]}
        slidesPerView={1}
        pagination={{clickable:true}}
        >
          {Clients.map((client, index) => {
            return(
              <SwiperSlide>
                <div className='test'>
                <img src={client.img} alt="" />
                <span>{client.review}</span>
                </div>
              </SwiperSlide>
            )
          })}
        </Swiper>

      </section>
    </>
  )
}

export default Testmonial
