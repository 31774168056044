import React, { useRef, useState } from 'react';
import "./ContactUs.css";

import { themeContext } from "../../Context";
import { useContext } from "react";


import emailjs from '@emailjs/browser';

const ContactUs = () => {
 const [done, setdone] = useState(false)
  const form = useRef();

  const sendEmail = (event) => {
    event.preventDefault();

    emailjs.sendForm('service_hwvh5nm', 'template_36z6ksl', form.current, 'p2CDiBvrSmsosvBLW')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
      event.target.reset();
      setdone(true)
  };

  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;
  return (
    <section className="ContactUs" id='Contact'>
      <div className="contact-left">
        <div className="profolio-color">
          <span     style={{
                background: darkMode ? "black" : "",
                color: darkMode ? "white" : "",
              }}>Get in touch</span>
          <span>Contact me</span>
          <div
          className="blur c-blur-1"
          style={{background: "#ABF1FF94"}}
          ></div>
        </div>
      </div>
      <div className="contact-right">
        <form ref={form} onSubmit={sendEmail}>
          <input type="text" name="user_name" className="user" placeholder="Name" />
          <input type="email" name="user_email" className="user" placeholder="Email" />
          <textarea name="message" className="user" placeholder="Message" />
          <input type="submit" value="Send" className='btn'/>
          {done && "Thanks for contacting me!!"}
          <div
          className="blur s-blur-2"
          style={{background: "var(--purple)"}}
          ></div>
        </form>
      </div>
    </section>
  );
};

export default ContactUs;
