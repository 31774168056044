import React from "react";
import "./Work.css";

import facebook from '../../img/Facebook.png'
import amazone from '../../img/amazon.png'
import fivver from '../../img/fiverr.png'
import upwork from '../../img/Upwork.png'
import Shopify from '../../img/Shopify.png'

import { themeContext } from "../../Context";
import { useContext } from "react";

import { motion } from "framer-motion";

const Work = () => {
  const theme = useContext(themeContext);
  const darkMode = theme.state.drakMode;
  return (
    <>
      <section className="work" id="Work">
        <div className="services-left leftii">
          <h2     style={{
                background: darkMode ? "black" : "",
                color: darkMode ? "white" : "",
              }}>Work for All these</h2>
          <h2>Brands & Clients</h2>
          <p     style={{
                background: darkMode ? "black" : "",
                color: darkMode ? "white" : "",
              }}>
            Lorem ipsum dolor sit, amet consectetur adipisici quos
            <br />
            deserunt unde, distinctio itaque nulla
            <br />
            Lorem ipsum, dolor sit amet consectetur adipisicing elit
            <br />
            Lorem ipsum dolor amet adipisicing elit.
          </p>

          <button className="btn s-btn">Hire me</button>
          <div
            className="blur s-blur"
            style={{ background: "#ABF1FF94" }}
          ></div>
        </div>

        <div className="work-right">
          <motion.div
                    initial={{rotate : 45}}
                    whileInView={{rotate : 0}}
                    viewport={{margin : '-40px'}}
                    transition= {{duration: 3.5, type: 'spring'}}
          className="main-circle">
            <div className="second-circle">
              <img src={upwork} alt="" className="upwork"/>
            </div>
            <div className="second-circle">
              <img src={fivver} alt="" className="fiver"/>
            </div>
            <div className="second-circle">
              <img src={amazone} alt="" className="amazone"/>
            </div>
            <div className="second-circle">
              <img src={Shopify} alt="" className="shopify"/>
            </div>
            <div className="second-circle">
              <img src={facebook} alt="" className="facebook"/>
            </div>
          </motion.div>
          <div className="back-circle blue-circle"></div>
          <div className="back-circle yellow-circle"></div>
        </div>
      </section>
    </>
  );
};

export default Work;
